/**
 * Owl Carousel v2.3.4
 * Copyright 2013-2018 David Deutsch
 * Licensed under: SEE LICENSE IN https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE
 */
/*
 *  Owl Carousel - Core
 */

.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1; }
  .owl-carousel .owl-stage {
    position: relative;
    -ms-touch-action: pan-Y;
    touch-action: manipulation;
    -moz-backface-visibility: hidden;
    /* fix firefox animation glitch */ }
  .owl-carousel .owl-stage:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0; }
  .owl-carousel .owl-stage-outer {
    position: relative;
    overflow: hidden;
    /* fix for flashing background */
    -webkit-transform: translate3d(0px, 0px, 0px); }
  .owl-carousel .owl-wrapper,
  .owl-carousel .owl-item {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0); }
  .owl-carousel .owl-item {
    position: relative;
    min-height: 1px;
    float: left;
    -webkit-backface-visibility: hidden;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none; }
  .owl-carousel .owl-item img {
    display: block;
    width: 100%; }
  .owl-carousel .owl-nav.disabled,
  .owl-carousel .owl-dots.disabled {
    display: none; }
  .owl-carousel .owl-nav .owl-prev,
  .owl-carousel .owl-nav .owl-next,
  .owl-carousel .owl-dot {
    cursor: pointer;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
  .owl-carousel .owl-nav button.owl-prev,
  .owl-carousel .owl-nav button.owl-next,
  .owl-carousel button.owl-dot {
    background: none;
    color: inherit;
    border: none;
    padding: 0 !important;
    font: inherit; }
  .owl-carousel.owl-loaded {
    display: block; }
  .owl-carousel.owl-loading {
    opacity: 0;
    display: block; }
  .owl-carousel.owl-hidden {
    opacity: 0; }
  .owl-carousel.owl-refresh .owl-item {
    visibility: hidden; }
  .owl-carousel.owl-drag .owl-item {
    -ms-touch-action: pan-y;
        touch-action: pan-y;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
  .owl-carousel.owl-grab {
    cursor: move;
    cursor: grab; }
  .owl-carousel.owl-rtl {
    direction: rtl; }
  .owl-carousel.owl-rtl .owl-item {
    float: right; }

/* No Js */
.no-js .owl-carousel {
  display: block; }

/*
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel .animated {
  animation-duration: 1000ms;
  animation-fill-mode: both; }

.owl-carousel .owl-animated-in {
  z-index: 0; }

.owl-carousel .owl-animated-out {
  z-index: 1; }

.owl-carousel .fadeOut {
  animation-name: fadeOut; }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

/*
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
  transition: height 500ms ease-in-out; }

/*
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item {
  /**
			This is introduced due to a bug in IE11 where lazy loading combined with autoheight plugin causes a wrong
			calculation of the height of the owl-item that breaks page layouts
		 */ }
  .owl-carousel .owl-item .owl-lazy {
    opacity: 0;
    transition: opacity 400ms ease; }
  .owl-carousel .owl-item .owl-lazy[src^=""], .owl-carousel .owl-item .owl-lazy:not([src]) {
    max-height: 0; }
  .owl-carousel .owl-item img.owl-lazy {
    transform-style: preserve-3d; }

/*
 * 	Owl Carousel - Video Plugin
 */
.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000; }

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  background: url("owl.video.play.png") no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: transform 100ms ease; }

.owl-carousel .owl-video-play-icon:hover {
  -ms-transform: scale(1.3, 1.3);
      transform: scale(1.3, 1.3); }

.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none; }

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity 400ms ease; }

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%; }

/*UDUCAT:GLP-2: 2025-03-31:1551_24*/
:root {
  --udu__less-version: "2025-03-31:1551_24";
}
/**
 * Owl Carousel v2.3.4
 * Copyright 2013-2018 David Deutsch
 * Licensed under: SEE LICENSE IN https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE
 */
/*
 *  Owl Carousel - Core
 */
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1;
}
.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
  touch-action: manipulation;
  -moz-backface-visibility: hidden;
  /* fix firefox animation glitch */
}
.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  /* fix for flashing background */
  -webkit-transform: translate3d(0px, 0px, 0px);
}
.owl-carousel .owl-wrapper,
.owl-carousel .owl-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
}
.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}
.owl-carousel .owl-item img {
  display: block;
  width: 100%;
}
.owl-carousel .owl-nav.disabled,
.owl-carousel .owl-dots.disabled {
  display: none !important;
}
.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-dot {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next,
.owl-carousel button.owl-dot {
  background: none;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit;
}
.owl-carousel.owl-loaded {
  display: block;
}
.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}
.owl-carousel.owl-hidden {
  opacity: 0;
}
.owl-carousel.owl-refresh .owl-item {
  visibility: hidden;
}
.owl-carousel.owl-drag .owl-item {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab;
}
.owl-carousel.owl-rtl {
  direction: rtl;
}
.owl-carousel.owl-rtl .owl-item {
  float: right;
}
/* No Js */
.no-js .owl-carousel {
  display: block;
}
/*
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel .animated {
  animation-duration: 1000ms;
  animation-fill-mode: both;
}
.owl-carousel .owl-animated-in {
  z-index: 0;
}
.owl-carousel .owl-animated-out {
  z-index: 1;
}
.owl-carousel .fadeOut {
  animation-name: fadeOut;
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
/*
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
  transition: height 500ms ease-in-out;
}
/*
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item {
  /**
			  This is introduced due to a bug in IE11 where lazy loading combined with autoheight plugin causes a wrong
			  calculation of the height of the owl-item that breaks page layouts
		   */
}
.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity 400ms ease;
}
.owl-carousel .owl-item .owl-lazy[src^=""],
.owl-carousel .owl-item .owl-lazy:not([src]) {
  max-height: 0;
}
.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d;
}
/*
 * 	Owl Carousel - Video Plugin
 */
.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000;
}
.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  background: url("owl.video.play.png") no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: transform 100ms ease;
}
.owl-carousel .owl-video-play-icon:hover {
  -ms-transform: scale(1.3, 1.3);
  transform: scale(1.3, 1.3);
}
.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none;
}
.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity 400ms ease;
}
.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%;
}
.udu__prod-slider-itm-disp-flex .owl-item .item.udu__prod-gal-opacity-fixed-v2 {
  display: flex !important;
  opacity: 1 !important;
}
.udu__prod-slider-itm-disp-block .owl-item .item {
  display: block !important;
  opacity: 1 !important;
}
._ .udu__indexSliderControl:hover .owl-controls-auto,
._ .udu__indexSliderControl:hover .bx-controls-auto,
._ .udu__indexSliderControl:hover .nivo-controls-auto {
  transition: all 1s ease-out 0s;
  visibility: visible;
}
._ .udu__indexSliderControl:hover .owl-controls-auto a:not(.active),
._ .udu__indexSliderControl:hover .bx-controls-auto a:not(.active),
._ .udu__indexSliderControl:hover .nivo-controls-auto a:not(.active) {
  opacity: 1;
  transform: scale(1);
  z-index: 505;
}
._ .udu__indexSliderControl .bx-controls-auto.udu__mode-v2 {
  bottom: 13px !important;
  display: inline-flex !important;
  height: auto;
  left: 50% !important;
  right: auto !important;
  top: auto !important;
  visibility: visible;
  width: auto;
}
._ .udu__indexSliderControl .bx-controls-auto.udu__mode-v2 a {
  border: 1px solid #fff !important;
  cursor: pointer;
  height: 25px !important;
  margin-left: 3px;
  opacity: 0.7 !important;
  position: relative !important;
  transform: scale(1);
  width: 25px !important;
}
._ .udu__indexSliderControl .bx-controls-auto.udu__mode-v2 a.active {
  border: 1px solid #f00 !important;
  opacity: 1 !important;
}
._ .udu__indexSliderControl .bx-controls-auto.udu__mode-v2 a.bx-start::before {
  border-top: 7px solid transparent !important;
  border-bottom: 7px solid transparent !important;
  border-left: 11px solid rgba(255, 255, 255, 0.6) !important;
  right: 4px !important;
  top: 5px !important;
}
._ .udu__indexSliderControl .bx-controls-auto.udu__mode-v2 a.bx-stop::before,
._ .udu__indexSliderControl .bx-controls-auto.udu__mode-v2 a.bx-stop::after {
  left: 6px;
  top: 5px;
  display: block;
  height: 13px;
  width: 5px;
}
._ .udu__indexSliderControl .bx-controls-auto.udu__mode-v2 a.bx-stop::after {
  left: auto;
  right: 5px;
  top: 5px;
}
._ .udu__indexSliderControl .owl-controls-auto,
._ .udu__indexSliderControl .bx-controls-auto,
._ .udu__indexSliderControl .nivo-controls-auto {
  height: 70px;
  position: absolute;
  right: calc(50% - 35px);
  top: calc(50% - 35px);
  width: 70px;
}
._ .udu__indexSliderControl .owl-controls-auto a,
._ .udu__indexSliderControl .bx-controls-auto a,
._ .udu__indexSliderControl .nivo-controls-auto a {
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 50%;
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  font-size: 0 !important;
  height: 70px;
  bottom: 0;
  opacity: 0;
  padding: 5px;
  position: absolute;
  right: 0;
  transform: scale(0.6);
  transition: all 0.3s ease-in-out 0s, opacity 1s ease-out 0s, scale 1s ease-out 0s;
  width: 70px;
  z-index: 0;
}
._ .udu__indexSliderControl .owl-controls-auto a:hover,
._ .udu__indexSliderControl .bx-controls-auto a:hover,
._ .udu__indexSliderControl .nivo-controls-auto a:hover {
  background-color: rgba(0, 0, 0, 0.6);
}
._ .udu__indexSliderControl .owl-controls-auto a:hover.play::before,
._ .udu__indexSliderControl .bx-controls-auto a:hover.play::before,
._ .udu__indexSliderControl .nivo-controls-auto a:hover.play::before,
._ .udu__indexSliderControl .owl-controls-auto a:hover.bx-start::before,
._ .udu__indexSliderControl .bx-controls-auto a:hover.bx-start::before,
._ .udu__indexSliderControl .nivo-controls-auto a:hover.bx-start::before {
  border-left: 30px solid rgba(255, 255, 255, 0.8);
}
._ .udu__indexSliderControl .owl-controls-auto a:hover.stop::before,
._ .udu__indexSliderControl .bx-controls-auto a:hover.stop::before,
._ .udu__indexSliderControl .nivo-controls-auto a:hover.stop::before,
._ .udu__indexSliderControl .owl-controls-auto a:hover.bx-stop::before,
._ .udu__indexSliderControl .bx-controls-auto a:hover.bx-stop::before,
._ .udu__indexSliderControl .nivo-controls-auto a:hover.bx-stop::before,
._ .udu__indexSliderControl .owl-controls-auto a:hover.stop::after,
._ .udu__indexSliderControl .bx-controls-auto a:hover.stop::after,
._ .udu__indexSliderControl .nivo-controls-auto a:hover.stop::after,
._ .udu__indexSliderControl .owl-controls-auto a:hover.bx-stop::after,
._ .udu__indexSliderControl .bx-controls-auto a:hover.bx-stop::after,
._ .udu__indexSliderControl .nivo-controls-auto a:hover.bx-stop::after {
  background-color: rgba(255, 255, 255, 0.8);
}
._ .udu__indexSliderControl .owl-controls-auto a.active,
._ .udu__indexSliderControl .bx-controls-auto a.active,
._ .udu__indexSliderControl .nivo-controls-auto a.active {
  opacity: 0;
  transform: scale(1);
  z-index: 49;
}
._ .udu__indexSliderControl .owl-controls-auto a.play::before,
._ .udu__indexSliderControl .bx-controls-auto a.play::before,
._ .udu__indexSliderControl .nivo-controls-auto a.play::before,
._ .udu__indexSliderControl .owl-controls-auto a.bx-start::before,
._ .udu__indexSliderControl .bx-controls-auto a.bx-start::before,
._ .udu__indexSliderControl .nivo-controls-auto a.bx-start::before {
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-left: 30px solid rgba(255, 255, 255, 0.6);
  content: " ";
  display: block;
  height: 0;
  position: absolute;
  right: 15px;
  top: 15px;
  transition: all 0.3s ease-in-out 0s;
  width: 0;
}
._ .udu__indexSliderControl .owl-controls-auto a.stop::before,
._ .udu__indexSliderControl .bx-controls-auto a.stop::before,
._ .udu__indexSliderControl .nivo-controls-auto a.stop::before,
._ .udu__indexSliderControl .owl-controls-auto a.bx-stop::before,
._ .udu__indexSliderControl .bx-controls-auto a.bx-stop::before,
._ .udu__indexSliderControl .nivo-controls-auto a.bx-stop::before,
._ .udu__indexSliderControl .owl-controls-auto a.stop::after,
._ .udu__indexSliderControl .bx-controls-auto a.stop::after,
._ .udu__indexSliderControl .nivo-controls-auto a.stop::after,
._ .udu__indexSliderControl .owl-controls-auto a.bx-stop::after,
._ .udu__indexSliderControl .bx-controls-auto a.bx-stop::after,
._ .udu__indexSliderControl .nivo-controls-auto a.bx-stop::after {
  background-color: rgba(255, 255, 255, 0.6);
  content: " ";
  display: block;
  height: 30px;
  position: absolute;
  transition: all 0.3s ease-in-out 0s;
  width: 10px;
}
._ .udu__indexSliderControl .owl-controls-auto a.stop:before,
._ .udu__indexSliderControl .bx-controls-auto a.stop:before,
._ .udu__indexSliderControl .nivo-controls-auto a.stop:before,
._ .udu__indexSliderControl .owl-controls-auto a.bx-stop:before,
._ .udu__indexSliderControl .bx-controls-auto a.bx-stop:before,
._ .udu__indexSliderControl .nivo-controls-auto a.bx-stop:before {
  left: 20px;
  top: 20px;
}
._ .udu__indexSliderControl .owl-controls-auto a.stop:after,
._ .udu__indexSliderControl .bx-controls-auto a.stop:after,
._ .udu__indexSliderControl .nivo-controls-auto a.stop:after,
._ .udu__indexSliderControl .owl-controls-auto a.bx-stop:after,
._ .udu__indexSliderControl .bx-controls-auto a.bx-stop:after,
._ .udu__indexSliderControl .nivo-controls-auto a.bx-stop:after {
  right: 20px;
  top: 20px;
}
._ #slider.udu__mainBannerDotsPosition-LB .owl-dots {
  justify-content: flex-start;
}
._ #slider.udu__mainBannerDotsPosition-CB .owl-dots {
  justify-content: center;
}
._ #slider.udu__mainBannerDotsPosition-RB .owl-dots {
  justify-content: flex-end;
}
._ #slider.has-custom-height .owl-item img {
  object-fit: cover;
  object-position: center;
  width: 100%;
}
._ #slider > li {
  position: relative;
}
._ #slider .text-widget-placeholder {
  display: block;
}
._ .owl-carousel .owl-nav .owl-prev,
._ .owl-carousel .owl-nav .owl-next {
  -webkit-text-size-adjust: none;
}
._ #slider .owl-carousel .udu__owl-inner-link {
  display: block;
}
._ #slider .owl-carousel .owl-item.active .slider-content.effect-1 .sub-title {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  animation-delay: 0.7s;
}
._ #slider .owl-carousel .owl-item.active .slider-content.effect-1 .main-title {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  animation-delay: 0.6s;
}
._ #slider .owl-carousel .owl-item.active .slider-content.effect-1 .desc {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  animation-delay: 0.5s;
}
._ #slider .owl-carousel .owl-item.active .slider-content.effect-1 .slider-btn {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  animation-delay: 0.4s;
}
._ #slider .owl-carousel .owl-item.active .slider-content.effect-2 .sub-title {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
  animation-delay: 0.7s;
}
._ #slider .owl-carousel .owl-item.active .slider-content.effect-2 .main-title {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
  animation-delay: 0.6s;
}
._ #slider .owl-carousel .owl-item.active .slider-content.effect-2 .desc {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
  animation-delay: 0.5s;
}
._ #slider .owl-carousel .owl-item.active .slider-content.effect-2 .slider-btn {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
  animation-delay: 0.4s;
}
._ #slider .owl-carousel .owl-item.active .slider-content.effect-3 .sub-title {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  animation-delay: 0.7s;
}
._ #slider .owl-carousel .owl-item.active .slider-content.effect-3 .main-title {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  animation-delay: 0.6s;
}
._ #slider .owl-carousel .owl-item.active .slider-content.effect-3 .desc {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  animation-delay: 0.5s;
}
._ #slider .owl-carousel .owl-item.active .slider-content.effect-3 .slider-btn {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  animation-delay: 0.4s;
}
._ #slider .owl-carousel .owl-item.active .slider-content.effect-4 .sub-title {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
  animation-delay: 0.7s;
}
._ #slider .owl-carousel .owl-item.active .slider-content.effect-4 .main-title {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
  animation-delay: 0.6s;
}
._ #slider .owl-carousel .owl-item.active .slider-content.effect-4 .desc {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
  animation-delay: 0.5s;
}
._ #slider .owl-carousel .owl-item.active .slider-content.effect-4 .slider-btn {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
  animation-delay: 0.4s;
}
._ #slider .owl-carousel .owl-caption {
  display: flex;
  flex-direction: column;
  height: calc(100% - 50px);
  justify-content: center;
  left: 0;
  margin: 25px auto;
  padding-left: 15px;
  padding-right: 15px;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}
._ #slider .owl-carousel .slider-content {
  width: 41.66666667%;
}
@media (min-width: 544px) and (max-width: 760px) {
  ._ #slider .owl-carousel .slider-content {
    width: 50%;
  }
}
._ #slider .owl-carousel .slider-content-left {
  align-self: flex-start;
}
._ #slider .owl-carousel .slider-content-center {
  align-self: center;
}
._ #slider .owl-carousel .slider-content-right {
  align-self: flex-end;
}
._ #slider .owl-carousel .slider-content .sub-title {
  background-color: #ff6799;
  border-radius: 5px;
  color: #ffffff;
  display: inline-block;
  font-size: 18px;
  font-weight: 900;
  letter-spacing: 1.5px;
  line-height: 24px;
  text-transform: unset;
  margin-bottom: 20px;
  padding: 10px 30px;
}
._ #slider .owl-carousel .slider-content .main-title {
  color: #232323;
  font-size: 75px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 80px;
  margin-bottom: 20px;
  text-transform: unset;
}
._ #slider .owl-carousel .slider-content .main-title span {
  display: block;
  font-weight: 700;
}
._ #slider .owl-carousel .slider-content .desc {
  color: #666666;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.5px;
  line-height: 28px;
  margin-bottom: 35px;
}
@media (max-width: 991px) {
  ._ .main #slider .owl-caption {
    max-width: 720px;
  }
}
@media (max-width: 760px) {
  ._ .main #slider .owl-caption {
    max-width: 576px;
  }
}
@media (max-width: 543px) {
  ._ .main #slider .owl-caption {
    max-width: 100%;
  }
}
@media (max-width: 991px) {
  ._ .main #slider .slider-content .sub-title {
    font-size: 14px;
    margin-bottom: 15px;
    padding: 8px 20px;
  }
  ._ .main #slider .slider-content .main-title {
    font-size: 40px;
    line-height: 45px;
    margin-bottom: 10px;
  }
  ._ .main #slider .slider-content .desc {
    font-size: 14px;
    margin-bottom: 20px;
    line-height: 24px;
  }
}
@media (max-width: 760px) {
  ._ .main #slider .slider-content .sub-title {
    letter-spacing: 1px;
    padding: 7px 15px;
    font-size: 13px;
  }
  ._ .main #slider .slider-content .main-title {
    font-size: 30px;
    line-height: 40px;
  }
  ._ .main #slider .slider-content .desc {
    display: none;
  }
  ._ .main #slider .slider-content .slider-btn {
    font-size: 12px;
    padding: 8px 17px;
  }
}
@media (max-width: 543px) {
  ._ .main #slider .slider-content .sub-title {
    background: transparent !important;
    font-weight: 700;
    line-height: 20px;
    margin-bottom: 5px;
    padding: 0;
  }
  ._ .main #slider .slider-content .main-title {
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 12px;
  }
  ._ .main #slider .slider-content .slider-btn {
    font-size: 11px;
    padding: 7px 15px;
  }
}
@media (max-width: 480px) {
  ._ .main #slider .slider-content .sub-title {
    font-size: 12px;
    line-height: 16px;
  }
  ._ .main #slider .slider-content .main-title {
    font-size: 24px;
    line-height: 35px;
    margin-bottom: 10px;
  }
  ._ .main #slider .slider-content .slider-btn {
    font-size: 10px;
    padding: 5px 10px;
  }
}
@media (max-width: 415px) {
  ._ .main #slider .slider-content .sub-title {
    font-size: 11px;
    line-height: 14px;
    margin-bottom: 10px;
  }
  ._ .main #slider .slider-content .main-title {
    font-size: 21px;
    line-height: 28px;
  }
  ._ .main #slider .slider-content .slider-btn {
    font-size: 9px;
    padding: 3px 8px;
  }
}
@media (max-width: 380px) {
  ._ .main #slider .slider-content .sub-title {
    font-size: 10px;
    margin-bottom: 5px;
  }
  ._ .main #slider .slider-content .main-title {
    font-size: 18px;
    line-height: 26px;
  }
}
@media (max-width: 350px) {
  ._ .main #slider .slider-content .sub-title {
    font-size: 9px;
  }
  ._ .main #slider .slider-content .main-title {
    font-size: 16px;
    line-height: 20px;
  }
}
@media (min-width: 1500px) {
  ._ .main.wide #slider .owl-caption {
    max-width: 1400px;
  }
}
@media (min-width: 1200px) and (max-width: 1499px) {
  ._ .main.wide #slider .owl-caption {
    max-width: 1170px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  ._ .main.wide #slider .owl-caption {
    max-width: 940px;
  }
}
@media (min-width: 1300px) and (max-width: 1499px) {
  ._ .main.wide #slider .slider-content .sub-title {
    font-size: 16px;
  }
  ._ .main.wide #slider .slider-content .main-title {
    font-size: 65px;
    line-height: 70px;
  }
  ._ .main.wide #slider .slider-content .desc {
    font-size: 16px;
  }
}
@media (min-width: 1200px) and (max-width: 1299px) {
  ._ .main.wide #slider .slider-content .main-title {
    font-size: 60px;
    line-height: 70px;
  }
  ._ .main.wide #slider .slider-content .desc {
    margin-bottom: 25px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  ._ .main.wide #slider .slider-content .sub-title {
    font-size: 15px;
  }
  ._ .main.wide #slider .slider-content .main-title {
    font-size: 50px;
    line-height: 50px;
  }
  ._ .main.wide #slider .slider-content .desc {
    font-size: 15px;
  }
}
@media (min-width: 1200px) {
  ._ .main:not(.wide) #slider .owl-caption {
    max-width: 720px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  ._ .main:not(.wide) #slider .owl-caption {
    max-width: 576px;
  }
}
._ .main:not(.wide) #slider .slider-content .desc {
  display: none;
}
@media (min-width: 1200px) {
  ._ .main:not(.wide) #slider .slider-content .sub-title {
    letter-spacing: 1px;
    padding: 7px 15px;
    font-size: 13px;
  }
  ._ .main:not(.wide) #slider .slider-content .main-title {
    font-size: 30px;
    line-height: 40px;
  }
  ._ .main:not(.wide) #slider .slider-content .slider-btn {
    font-size: 12px;
    padding: 8px 17px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  ._ .main:not(.wide) #slider .slider-content .sub-title {
    background: transparent !important;
    font-weight: 700;
    line-height: 20px;
    margin-bottom: 5px;
    padding: 0;
  }
  ._ .main:not(.wide) #slider .slider-content .main-title {
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 12px;
  }
  ._ .main:not(.wide) #slider .slider-content .slider-btn {
    font-size: 11px;
    padding: 7px 15px;
  }
}
@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
#slider {
  height: 0;
  overflow: hidden;
}
#slider:hover .owl-carousel .owl-nav .owl-prev,
#slider:hover .owl-carousel .owl-nav .owl-next {
  opacity: 1;
}
#slider:hover .owl-carousel .owl-nav .owl-prev {
  left: 20px;
}
#slider:hover .owl-carousel .owl-nav .owl-next {
  right: 20px;
}
#slider._done {
  height: auto;
}
#slider._done .owl-carousel {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-name: showSlider;
  animation-name: showSlider;
  animation-delay: 1s;
}
#slider .owl-carousel {
  height: auto;
  max-height: 0;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
#slider .owl-dots {
  align-items: center;
  bottom: 14px;
  display: flex;
  justify-content: center;
  left: 0;
  padding: 0 15px;
  position: absolute;
  right: 0;
}
@media (max-width: 760px) {
  #slider .owl-dots {
    bottom: 7px;
  }
}
#slider .owl-dots .owl-dot {
  display: inline-block;
  margin: 0 2px;
  vertical-align: middle;
}
#slider .owl-dots .owl-dot.active span,
#slider .owl-dots .owl-dot:hover span {
  background-color: var(--schema-color, #4acdd5);
  border-color: var(--schema-color, #4acdd5);
}
#slider .owl-dots .owl-dot span {
  background-color: transparent;
  border: 1px solid var(--schema-color, #4acdd5);
  border-radius: 50%;
  display: block;
  height: 12px;
  margin: 0 3px;
  position: relative;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  width: 12px;
}
@media (max-width: 760px) {
  #slider .owl-nav {
    display: none;
  }
}
#slider .owl-nav .owl-prev,
#slider .owl-nav .owl-next {
  background-image: url(img/slider-arrow.png);
  background-color: var(--schema-color, #4acdd5);
  border-radius: 50%;
  height: 44px;
  margin: 4px;
  opacity: 0;
  position: absolute;
  top: calc(50% - 22px);
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  width: 44px;
}
#slider .owl-nav .owl-prev:hover,
#slider .owl-nav .owl-next:hover {
  background-color: #FF6799;
}
#slider .owl-nav .owl-prev {
  background-position: -6px -6px;
  left: 60px;
  right: auto;
}
#slider .owl-nav .owl-next {
  background-position: -6px 50px;
  left: auto;
  right: 60px;
}
._ .category-list:hover .owl-nav .owl-prev,
._ .category-list:hover .owl-nav .owl-next {
  opacity: 1;
}
._ .category-list.owl-carousel {
  padding: 0 30px;
}
._ .category-list .owl-nav .owl-prev,
._ .category-list .owl-nav .owl-next {
  color: #999999;
  display: inline-block;
  font-size: 12px;
  font-weight: bold;
  height: 28px;
  opacity: 0;
  position: absolute;
  top: calc(50% - 14px);
  -webkit-transition: all 0.6s;
  -moz-transition: all 0.6s;
  -o-transition: all 0.6s;
  transition: all 0.6s;
  vertical-align: middle;
  width: 28px;
}
@media (max-width: 760px) {
  ._ .category-list .owl-nav .owl-prev,
  ._ .category-list .owl-nav .owl-next {
    opacity: 1;
  }
}
._ .category-list .owl-nav .owl-prev:hover:before,
._ .category-list .owl-nav .owl-next:hover:before {
  opacity: 0.7;
}
._ .category-list .owl-nav .owl-prev:before,
._ .category-list .owl-nav .owl-next:before {
  font-family: 'Linearicons';
}
._ .category-list .owl-nav .owl-prev.disabled,
._ .category-list .owl-nav .owl-next.disabled {
  opacity: 0;
}
._ .category-list .owl-nav .owl-prev {
  left: 0;
  right: auto;
}
._ .category-list .owl-nav .owl-prev:before {
  content: '\ec3b';
}
._ .category-list .owl-nav .owl-next {
  left: auto;
  right: 0;
}
._ .category-list .owl-nav .owl-next:before {
  content: '\ec3c';
}
._ .udu__index-cats-prods .owl-nav,
#indexCarousel .owl-nav {
  text-align: center;
  margin-bottom: 0;
  margin-top: 30px;
}
@media (max-width: 543px) {
  ._ .udu__index-cats-prods .owl-nav,
  #indexCarousel .owl-nav {
    margin-top: 25px;
  }
}
._ .udu__index-cats-prods .owl-nav button.owl-prev,
#indexCarousel .owl-nav button.owl-prev,
._ .udu__index-cats-prods .owl-nav button.owl-next,
#indexCarousel .owl-nav button.owl-next {
  border: 1px solid #c9c9c9 !important;
  border-radius: 30px;
  color: #999999;
  display: inline-block;
  font-size: 12px;
  font-weight: bold;
  height: 36px;
  margin: 0 3px;
  width: 36px;
  -webkit-transition: all 0.6s;
  -moz-transition: all 0.6s;
  -o-transition: all 0.6s;
  transition: all 0.6s;
}
._ .udu__index-cats-prods .owl-nav button.owl-prev:hover:not(.disabled),
#indexCarousel .owl-nav button.owl-prev:hover:not(.disabled),
._ .udu__index-cats-prods .owl-nav button.owl-next:hover:not(.disabled),
#indexCarousel .owl-nav button.owl-next:hover:not(.disabled) {
  background-color: var(--schema-color, #ff6799);
  border-color: var(--schema-color, #ff6799);
  color: #ffffff;
}
._ .udu__index-cats-prods .owl-nav button.owl-prev:before,
#indexCarousel .owl-nav button.owl-prev:before,
._ .udu__index-cats-prods .owl-nav button.owl-next:before,
#indexCarousel .owl-nav button.owl-next:before {
  font-family: Linearicons;
  vertical-align: middle;
}
._ .udu__index-cats-prods .owl-nav button.owl-prev.disabled,
#indexCarousel .owl-nav button.owl-prev.disabled,
._ .udu__index-cats-prods .owl-nav button.owl-next.disabled,
#indexCarousel .owl-nav button.owl-next.disabled {
  color: #2323234d;
}
._ .udu__index-cats-prods .owl-nav button.owl-prev:before,
#indexCarousel .owl-nav button.owl-prev:before {
  content: '\ec3b';
}
._ .udu__index-cats-prods .owl-nav button.owl-next:before,
#indexCarousel .owl-nav button.owl-next:before {
  content: '\ec3c';
}
#indexCarousel {
  padding: 35px 0;
}
@media (max-width: 760px) {
  #indexCarousel {
    padding: 20px 0;
  }
}
#indexCarousel .item {
  align-items: center;
  background: #ffffff;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}
#indexCarousel .item:hover {
  opacity: 1;
  transform: scale(0.9);
}
@keyframes showSlider {
  from {
    max-height: 0;
  }
  to {
    max-height: 1000px;
  }
}
